import * as Sentry from '@sentry/react';

import {logger} from '@renofi/utils';

export function initSentry() {
  const APP_ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_ENV;
  const dsn =
    process.env.REACT_APP_SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
  const release =
    process.env.REACT_APP_COMMIT_REF ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

  if (APP_ENV !== 'production') return;
  if (Sentry?.getCurrentHub()?.getClient()) {
    logger.log('Sentry is already initialized');
    return;
  }

  Sentry.init({
    dsn,
    release,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          'https://api-gateway.renofi.com/graphql',
          /^https:\/\/api-gateway.renofi\.com\/graphql/,
        ],
      }),
    ],
    // Capture Replay for 0% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api-gateway.renofi\.com\/graphql/,
    ],
    ignoreErrors: [
      'ResizeObserver loop',
      'The operation timed out',
      'Load failed',
      'Failed to fetch',
      'ChunkLoadError',
      'AbortError: The user aborted a request',
      "Can't find variable: acsbJS",
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
  });
}
