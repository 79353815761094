import React, {memo, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {omit} from 'lodash';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {
  mediaQuery,
  constants,
  useScreenSize,
  useUTMTags,
  useRaasSourceLender,
  useLeadExternalOriginType,
  getItem,
} from '@renofi/utils';
import {useSubmitScenario} from '@renofi/api';
import {logRocketSessionURL, sendEvent} from '@renofi/analytics';
import {
  fadeGray,
  lightGray,
  lightGreen,
  darkBlue,
} from '@renofi/utils/src/colors';
import {Modal, Badge} from '@renofi/components';
import {savePersonalLeadData} from '@renofi/utils/src/lead';
import {CHAT_ID_COOKIE_KEY} from '@renofi/utils/src/const';

import {useNewHomeownerCache} from '../api';
import {setNewHomeowner} from '../api/cache';
import {SectionHeading, SectionSummary, Slider} from '../components';
import {calculatedValuesVar} from '../api/cache/maxLoan';
import {Section, Strong} from '../Calculator/styled';
import MaxLoanCalculator from '../Calculator/MaxLoan/MaxLoan';
import RatesSlider from '../RatesSlider';
import AddDetails from '../Tooltips/Help/AddDetails';

import DetailsForm from './DetailsForm';
import SuccessModal from './SuccessModal';

function CalculatorPreview({scrollToRef}) {
  // Apollo
  const {newHomeowner} = useNewHomeownerCache();
  const {submitScenario, response} = useSubmitScenario();
  const {maxLoan} = useReactiveVar(calculatedValuesVar);
  // App
  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const raasSourceLender = useRaasSourceLender(utmTags);
  const leadExternalOriginType = useLeadExternalOriginType(utmTags);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [shake, setShake] = useState(false);
  const [formData, setFormData] = useState(newHomeowner);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const {isMobile} = useScreenSize();

  useEffect(() => {
    sendEvent('Calculator/New-Homeowner/Preview-Page');
  }, []);

  async function onSubmit(data) {
    onCloseDetails();
    setShowSuccessModal(true);
    setNewHomeowner(data);

    const conversationId = getItem(CHAT_ID_COOKIE_KEY);
    const variables = {
      ...newHomeowner,
      ...data,
      ...(conversationId ? {conversationId} : {}),
      sourceLenderCode: raasSourceLender,
      leadExternalOriginType,
      utmTags: {
        utmCampaign: utmTags.campaign,
        utmContent: utmTags.content,
        utmMedium: utmTags.medium,
        utmSource: utmTags.source,
        utmTerm: utmTags.term,
      },
      logrocketSessionUrl: logRocketSessionURL(),
    };
    const rsp = await submitScenario({
      variables: omit(variables, ['city', 'state', 'terms']),
    });
    const id = rsp?.data?.submitScenario?.lead?.id;
    savePersonalLeadData(data, id);
    setTimeout(() => setRedirect(true), 800);
    sendEvent('Calculator/Lead-Created', {email: formData.email});
    if (data.hasNewsletterConsent) {
      sendEvent('Calculator/Newsletter-Signup');
    }
  }

  function onShake() {
    setShake(true);
    setTimeout(() => setShake(false), 2000);
  }

  function onAddDetails() {
    history.push('/renovation/preview/details');
  }

  function onCloseDetails() {
    setNewHomeowner(formData);
    history.goBack();
  }

  if (!newHomeowner.zipCode) {
    history.push('/homeowner/zip');
    return null;
  }

  if (redirect) {
    return (
      <Redirect
        push
        to={`/new-renovation/${response?.lead?.calculatorToken}${
          isMobile ? '/2' : ''
        }`}
      />
    );
  }

  return (
    <>
      <Flex
        css={mediaQuery({
          flexDirection: ['column', 'row'],
        })}>
        <Section
          css={{
            borderRadius: '0 0 0 16px',
            flex: 39,
            paddingBottom: '0 !important',
          }}>
          <SectionHeading
            step={1}
            title="Explore your max borrowing power"
            color={lightGreen}
            titleColor="#0B7517"
          />

          <MaxLoanCalculator rouded isPreview />
        </Section>

        <Section
          css={{
            borderRadius: '0 0 16px 0',
            flex: 54,
            position: 'relative',
            paddingBottom: '0 !important',
          }}>
          <Box
            css={mediaQuery({
              top: 190,
              zIndex: 1,
              width: 279,
              textAlign: 'center',
              position: 'absolute',
              left: ['calc(50% - 140px)', 125],
            })}>
            <AddDetails onClick={onAddDetails} shake={shake} />
          </Box>

          <Box
            onClick={onShake}
            data-tip
            css={{
              filter: 'grayscale(100%)',
              opacity: 0.6,
            }}>
            <SectionHeading
              forwardRef={scrollToRef}
              titleColor={darkBlue}
              step={2}
              title="Explore estimated monthly payments"
              color={lightGray}
            />

            <SectionSummary
              disabled
              title="Estimated minimum monthly payment"
              value={0}
              color={fadeGray}
            />

            <Slider
              disabled
              large
              step={5000}
              label="Loan amount"
              value={100000}>
              <Box textAlign="right" mt="5px">
                <Badge color={lightGreen}>Max loan amount</Badge>
                <Strong>${maxLoan?.toLocaleString('en-US')}</Strong>
              </Box>
            </Slider>

            <RatesSlider rates={[0, 2, 3]} disabled value={0} />
          </Box>
        </Section>
      </Flex>

      <Route exact path="/renovation/preview/details">
        <Modal
          width={740}
          title="Personalize to calculate payments and rates"
          show={!showSuccessModal}
          onClose={onCloseDetails}>
          <DetailsForm
            formData={formData}
            onChange={(value) => setFormData({...formData, ...value})}
            onClose={onCloseDetails}
            onSubmit={onSubmit}
          />
        </Modal>
      </Route>

      <Modal hideCloseButton show={showSuccessModal}>
        <SuccessModal />
      </Modal>
    </>
  );
}

CalculatorPreview.propTypes = {
  scrollToRef: PropTypes.object,
};

export default memo(CalculatorPreview);
